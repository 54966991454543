.flip-card {
    width: 22vw; 
    height: 17vw; 
    perspective: 1000px;
    margin: 16px;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
    border-radius: 10px; 
    overflow: hidden; 
}

@media (max-width: 1024px) { 
    .flip-card {
        width: 40vw; 
        height: 40vw; 
    }
}

@media (max-width: 768px) { 
    .flip-card {
        width: 80vw; 
        height: 80vw; 
    }
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
}
  
.flip-card-inner:hover {
    transform: rotateY(180deg);
}
  
.card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px; 
}

.card-front, .card-back {
    background: linear-gradient(145deg, #f8f9fa 0%, #d1e7dd 100%);
    color: #343a40;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ced4da;
}

.card-back {
    transform: rotateY(180deg);
}
