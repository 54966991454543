.navbar-container {
    background-color: black;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    height: 80px;
    width: 100%;
}

.logo {
    width: 40px;
    height: 40px;
    margin-top: 10px; 
}

.logo img {
    width: 100%;
    height: auto;
}

.navbar-elements {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}


.navbar-elements ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: rotate(-90deg) translateX(-50%) translateY(-50%); 
    transform-origin: left top; 
    display: flex;
    flex-direction: row-reverse;
    gap: 45px;
    align-items: center;
}

.navbar-elements li {
    color: #fafafa;
}

.navbar-elements li a {
    color: #fafafa; 
    text-decoration: none; 
}

.navbar-elements li a:hover {
    color: gray; 
}